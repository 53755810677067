import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import arrow_curve from "../assets/icons/arrow-curve.svg";
import hotel_img from "../assets/LuxuriousBedroomViewCityWindowBreakfastTrayBed.png";
import jean_francois from "../assets/jean-francois.png";
import yves_abitbol from "../assets/yves-abitbol.png";
import icon_star_outine from "../assets/icons/icon-star-outine.svg";
import icon_star from "../assets/icons/icon-stars.svg";
import icon_computer from "../assets/icons/icon-computer.svg";

import img_5 from "../assets/BusinessWomanReceivingCard.png";
import img_6 from "../assets/img6.png";
import img_7 from "../assets/TwoHandsFramingBeautifulLandscape.png";
import img_8 from "../assets/SerenityOfMorningCoffeeInSunlitBedroom.png";

import phone_circle from "../assets/icons/phone-circle.svg";
import right_arrow from "../assets/icons/right-arrow.svg";

import img_11 from "../assets/img11.png";
import icon_block_hand from "../assets/icons/icon-block-hand.svg";

import { addRegistration } from '../api/contact_registration'

import { LazyImage } from '../components/LazyImage/LazyImage'


export const Become_Partner = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  const [RegistrationData, setRegistrationData] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    email: '',
    company: '',
    category: 'partner',
    message: '',
    type: 'join',
  });

  const [errors, setErrors] = useState({}); // State to store validation errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData({ ...RegistrationData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(RegistrationData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return -1;
    }
    // console.log(RegistrationData);
    addRegistration(RegistrationData, (response) => {
      if (response?.status === 'success') {
        alert('Information submitted successfully')

        //
        setRegistrationData({
          firstname: '',
          lastname: '',
          phone: '',
          email: '',
          company: '',
          category: 'partner',
          message: '',
          type: 'join'
        });
        //
      } else {
        console.log(response?.message);
        alert(`Error occured`)
      }
    })
  }

  const validateForm = (data) => {
    const errors = {};

    if (data.email.trim() === '') {
      errors.email = 'Valid Email is required';
    }

    if (data.firstname.trim() === '') {
      errors.firstname = 'First Name is required';
    }

    if (data.lastname.trim() === '') {
      errors.lastname = 'Last Name is required';
    }
    if (data.phone.trim() === '') {
      errors.phone = 'Phone is required';
    }
    if (data.company.trim() === '') {
      errors.company = 'Company is required';
    }
    return errors;
  }

  const linkStyle = {
    cursor: 'pointer'
  };
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Create an Hospitality Worth Falling In Love For - MyHotelMatch</title>
        <link rel="canonical" href="https://myhotelmatch.com/partner" />
        <meta name="description" content="Unlock growth opportunities and exciting collaborations when you become our partner at MyHotelMatch. Explore the advantages of a partnership with us." />
        <meta name="keywords" content="Hotel, luxury, tailor-made, stay, Boutique accommodations, Lavish lodging, Exclusive retreat, Personalized escape, Qualitative hotel, Customized stay, Tailored luxury, Bespoke hospitality, Handcrafted experience, High-end residence, Deluxe suite, Unique lodging, Tailor-made amenities, Luxury accommodations, Tailored hospitality, Luxury retreat, Tailored comfort, Premium stay, Customized lodging, VIP hotel, Luxury getaway, Tailor-made service, Upscale accommodations, Tailored vacation, Luxury oasis" />
      </Helmet>

      <div className="main-content">
        <div className="main-title">
          <h1 className="orange-big-title">CREATE A <span className="title-blck">H<img src={icon_star_outine} alt="OrangeStarOutLine" className="pad-bt-10 start-o" />SPITALITY </span></h1>
          <h2 className="black-big-title">worth falling in love for</h2>

          {/* <h1 className="orange-big-title">CREATE AN H<img src={icon_star_outine} alt="" className="pad-bt-10" />SPITALITY </h1>
        <h2 className="black-big-title">worth falling in love for</h2> */}
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-5">
              <div className="half-img"> <LazyImage image={{ src: hotel_img, className: "img-fluid", alt: "LuxuriousBedroomWithViewOfCityFromWindowAndBreakfastTrayOnBed" }} /> </div>
            </div>
            <div className="col-md-12 col-lg-7">
              <div className="object-container mob-text-cent">
                <div className="d-flex mob-blck ipad-blck">
                  <div className="flex-shrink-0 partner-thumb"> <LazyImage image={{ src: jean_francois, className: "thumb-round", alt: "Jean Francois" }} />
                    <p className="thumb-title"> Jean-François Ott <span>Executive Chairman</span> </p>
                  </div>
                  <div className="flex-grow-1 ms-5 thumb-p"> “As a hotel owner and relentless traveler with a deep-rooted love for the industry and a relentless traveler, I've felt a profound responsibility to give back to this profession. We promise to provide you with the key knowledge needed to elevate your offerings. We're here to modernize the booking experience, empower your properties, and ensure guests experience the best.” </div>
                </div>
                <div className="d-flex mob-blck ipad-blck mb-4">
                  <div className="flex-grow-1 thumb-p"> “Our will has always been to provide hotel managers with the tools and insights they need to thrive in an ever-changing industry. And what better source than the traveler itself, giving them willingly ? Together, we can co-create solutions, innovate, and elevate the guest experience.<br />
                    Let's redefine hospitality and propel your hotels to new heights.” </div>
                  <div className="flex-shrink-0  ms-5 partner-thumb"> <LazyImage image={{ src: yves_abitbol, className: "thumb-round", alt: "Yves Abitbol" }} />
                    <p className="thumb-title"> Yves Abitbol <span>CEO</span> </p>
                  </div>
                </div>
                <div className="click-here"> <Link style={linkStyle} onClick={() => scrollToSection('sectionFormContact')} className="btn btn-dark orange-btn">Join the movement</Link> <span className="click-pos"><img src={arrow_curve} alt="OrangeArrowCurve" /> click here</span> </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container numbers-cont">
          <div className="row">
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="numb-box">
                <h5>99,88</h5>
                <p>billion USD <br /> Luxury hotel market <br /> sales in 2023</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="numb-box">
                <h5>146,05</h5>
                <p>billion USD <br /> Sales forecast <br /> to 2028</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="numb-box">
                <h5>38</h5>
                <p>Average number of websites <br /> visited per customer before <br /> booking</p>
              </div>
            </div>
            <div className="col-sm-6 col-md-6 col-lg-3">
              <div className="numb-box">
                <h5>120</h5>
                <p>Among the top 10 cities, <br />  average number of 5-star <br />  hotels per city</p>
              </div>
            </div>
          </div>
          <p className="orange-p mt-5 pt-5">Source: Mondor Intelligence</p>
        </div>
        <div className="container-fluid reviews-cont">
          <video autoPlay loop muted playsInline>
            <source src="https://storage.googleapis.com/mhm-medias/store-front/video-4.mp4" type="video/mp4" />
            Your browser does not support the video tag. </video>
          <div className="container review-box-cont">
            <div className="row review-bx">
              <div className="col">
                <div className="card h-100">
                  <div className="card-body">
                    <p className="card-text">Less than 50% of luxury consumers surveyed consider themselves satisfied with their customer experience.</p>
                  </div>
                  <div className="card-footer"> <small>Joël Hazan,<br />
                    BCG</small> </div>
                </div>
              </div>
              <div className="col col-lg-5">
                <div className="card h-100">
                  <div className="card-body">
                    <p className="card-text">When customers leave a luxury boutique, they have the object they have bought. When they leave a hotel, they have nothing but the memory of their experience. For that reason alone, it has to be the best possible.</p>
                  </div>
                  <div className="card-footer"> <small>Pascal Billard, <br />
                    Director of the Majestic Hotel & Spa Barcelona <img src={icon_star} alt="OrangeStar" /> </small> </div>
                </div>
              </div>
              <div className="col">

                <div className="card h-100">
                  <div className="card-body">
                    <p className="card-text">If there's one sector where professionals need to know their customers inside out, it's the luxury sector.</p>
                  </div>
                  <div className="card-footer"> <small>Alison Gilmore, <br />
                    Managing Director of ILTM trade shows</small> </div>
                </div>
              </div>
            </div>
            <p className="source-txt">Source: www.journalduluxe.fr ; www.tourmag.com ; voyage.tv5monde.com</p>
          </div>
        </div>
        <div id="sectionFormContact_" className="container mb-5">
          <div className="row">
            <div className="col-md-12 col-lg-8 ">
              <div className="muscle-container">
                <div className="d-flex justify-content-center mb-5">
                  <h3 className="title-sub shuffle-txt tex-big orange-txt">MUSCLE Y<img src={icon_star_outine} alt="OrangeStarOutline" className="start-o-1 pad-bt-10" />UR GAME
                    <div className="sub">with our unique <span className="art-text">features</span></div>
                  </h3>
                </div>
                <div className="d-flex mob-blck ipad-blck align-items-center mb-5 pb-5">
                  <div className="flex-shrink-0">
                    <div className="client-img ipad-tex-cent"><LazyImage image={{ src: img_5, alt: 'BusinessWomanReceivingCard' }} /></div>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h3 className="title-sub mob-text-cent">GET THE CLIENTS
                      <div className="sub">YOU <span className="art-text">WANT</span></div>
                    </h3>
                    <p className="home-p text-end mob-text-cent">Our advanced AI matching technology constantly refines hotel recommendations. You can trust that the guests referred to your property truly seek the experience you provide, leading to positive word-of-mouth referrals and repeat bookings.</p>
                  </div>
                </div>
                <div className="d-flex mob-blck ipad-blck align-items-center mb-5 pb-5">
                  <div className="flex-grow-1">
                    <h3 className="title-sub text-end ipad-tex-cent">TRUE <span className="art-text">insights</span>
                      <div className="sub text-start ipad-tex-cent">KNOWLEDGE
                        <div className="sub text-end ipad-tex-cent">IS POWERFUL </div>
                      </div>
                    </h3>
                    <div className="clearfix"></div>
                    <p className="home-p text-end mob-text-cent"> MyHotelMatch is a permanent source of feedback, even from those who choose alternative accommodations. This feedback loop enables you to continuously improve your hotel's value propositions.</p>
                  </div>
                  <div className="flex-shrink-0 ms-3">
                    <div className="amazing-text">
                      <div>
                        <video autoPlay loop muted playsInline>
                          <source src="https://storage.googleapis.com/mhm-medias/store-front/video-5.mp4" type="video/mp4" />
                          Your browser does not support the video tag. </video>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex mob-blck ipad-blck align-items-center mb-5 pb-5">
                  <div className="flex-shrink-0">
                    <div className="line-sm-left mb-5 ipad-tex-cent"> <LazyImage image={{ src: img_7, alt: 'TwoHandsFramingBeautifulLandscape' }} /> </div>
                  </div>
                  <div className="flex-grow-1 ms-3  mob-text-cent">
                    <h3 className="title-sub  mob-text-cent"><span className="art-text">customers</span>
                      <div className="sub">WHO COMES BACK </div>
                    </h3>
                    <p className="home-p"> Guests on our platform openly share what they want, their preferences, and what they are seeking in a hotel stay. This is pure gold information, allowing you to micro-calibrate your offerings to cater to each individual's unique needs, resulting in higher guest satisfaction and more positive reviews.</p>
                  </div>
                </div>
                <div className="d-flex mob-blck ipad-blck align-items-center mb-5 pb-5">
                  <div className="flex-grow-1 mb-5">
                    <h3 className="title-sub text-end  mob-text-cent">A <span className="art-text pr-100">label</span>
                      <div className="sub pad-0">OF EXCLUSIVITY</div>
                    </h3>
                    <div className="clearfix"></div>
                    <p className="home-p text-end  mob-text-cent"> We are providing a game-changing environment: you only compete to maximum 5 other hotels for each customer. This is a pipeline of ultra qualified leads. You're guaranteed to invest time with customers who are in line with your hotel's strengths. This reduces marketing waste, optimises your time and, ultimately, increases your return on investment. </p>
                  </div>
                  <div className="flex-shrink-0 ms-3">
                    <div className="curve-line ipad-tex-cent"><LazyImage image={{ src: img_8, alt: 'WomanInWhiteRobeEnjoyingMorningCoffeeInSunlitBedroomWithBlueCurtains' }} /></div>
                  </div>
                </div>
                <div className="text-center"> <Link to={"/contact"} className="btn btn-dark orange-btn">Book a phone call with us <img src={phone_circle} alt="OrangePhoneCircle" /></Link> </div>
              </div>
            </div>
            <div id="sectionFormContact" className="col-md-12 col-lg-4">
              <div className="sticky-top pt-4">
                <div className="card form sticky-form">
                  <form onSubmit={handleSubmit}>
                    <div className="card-body" style={{ fontSize: '10px', color: 'red' }}>
                      <h3 className="title-sm">Would you like to be<br />
                        contacted? <img src={icon_computer} alt="OrangeComputer" /></h3>

                      <span className="error">{errors.email}</span>
                      <input
                        type="text"
                        className="form-line"
                        placeholder="Email*"
                        name="email"
                        value={RegistrationData.email}
                        onChange={handleChange}
                        onInput={(e) => {
                          const inputValue = e.target.value;
                          if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputValue)) {
                            e.target.setCustomValidity('Please enter a valid email address');
                          } else {
                            e.target.setCustomValidity('');
                          }
                        }}

                      />
                      <span className="error">{errors.firstname}</span>
                      <input
                        type="text"
                        className="form-line"
                        placeholder="First Name*"
                        name="firstname"
                        onChange={handleChange}
                        value={RegistrationData.firstname}

                      />
                      <span className="error">{errors.lastname}</span>
                      <input
                        type="text"
                        className="form-line"
                        placeholder="Last Name*"
                        name="lastname"
                        onChange={handleChange}
                        value={RegistrationData.lastname}

                      />
                      <span className="error">{errors.phone}</span>
                      <input
                        type="text"
                        className="form-line"
                        placeholder="Phone Number*"
                        name="phone"
                        onChange={handleChange}
                        value={RegistrationData.phone}
                        onInput={(e) => {
                          // Remove any non-numeric characters from the input
                          e.target.value = e.target.value.replace(/[^0-9+]/g, '');
                        }}

                      />
                      <span className="error">{errors.company}</span>
                      <input
                        type="text"
                        className="form-line"
                        placeholder="Company name*"
                        name="company"
                        onChange={(e) => { handleChange(e) }}
                        value={RegistrationData.company}
                      />

                      <div className="text-start"> <button className="btn btn-dark  orange-btn btn-with-arrow" type="submit" >Reach us</button> </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid pad-lef-15">
        <div className="footer-banner"> <img src={img_11} alt="" />
          <h3>You are usually the one taking care of others,<br />
            but we have a little something for you</h3>
          <p>Download the white paper the 8 key facts<br />
            that are changing the way you travel in 2023.</p>
          <a href="#" className="btn btn-dark orange-btn">Download <img src={right_arrow} alt="" /></a> </div>
      </div> */}
        {/* <div className="container customer-cont">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-8">
            <h3 className="title-sub">ARE YOU ALREADY
              <div className="sub"><img src={icon_block_hand} alt="" />an MHM <span className="art-text line-3">customer?</span></div>
            </h3>
            <p className="home-p ms-5 mt-3 mb-5">10 tips for taking charge of the Back Office, for good onboarding!</p>
            <div className="text-center"><a href="#" className="btn btn-dark orange-btn">Click here <img src={right_arrow} alt="" /></a></div>
          </div>
          <div className="col-sm-12"></div>
        </div>
      </div> */}
      </div>
    </>
  );
};
