import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import right_arrow from "../assets/icons/right-arrow.svg";

import stack_1 from "../assets/stack/LaponiaWithReindeerAndAndreas.png";
import stack_2 from "../assets/stack/AmangiriHotelRetreatWithScenicView.png";
import stack_3 from "../assets/stack/CapturingNatureFromGeodesicDomeWindow.png";
import stack_4 from "../assets/stack/LuxuriousPoolArea.png";
import stack_5 from "../assets/stack/ModernHotelBedroomInteriorDesign.png";
import stack_6 from "../assets/stack/TurquoiseLakeBoatsMountains.png";
import stack_7 from "../assets/stack/BackpackerOnCliffAtSunset.png";

import icon_arrow_right_orange from "../assets/icons/icon-arrow-right-orange.svg";
import icon_charge from "../assets/icons/icon-charge.svg";
import icon_computer_1 from "../assets/icons/icon-computer-1.svg";
import icon_mask from "../assets/icons/icon-mask.svg";
import icon_luggage from "../assets/icons/icon-luggage.svg";

import { LazyImage } from '../components/LazyImage/LazyImage'


export const Discover = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>MyHotelMatch</title>
        <link rel="canonical" href="https://myhotelmatch.com/discover" />
        <meta name="description" content="MyHotelMatch" />
        <meta name="keywords" content="MyHotelMatch, journal" />

      </Helmet>

      <div className="main-content">
        <div className="main-title">
          <div className="d-flex justify-content-center">
            <h3 className="title-sub orange-txt shuffle-txt tex-big">NIBBLING
              <div className="sub">the <span className="art-text globe">world</span></div>
            </h3>
          </div>
          <div className="container">
            <p className="title-p">Dive into a world of hidden boutique hotels, unearth niche destinations, and explore the captivating ties between hotels and art. Discover what we love with our children eyes : valuable and least known stories, from our guests, partners, industry insights or culture.</p>
          </div>
        </div>
        <div className="container">
          <div className="check-container">
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1" />
              <label className="form-check-label" for="inlineCheckbox1">All</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2" />
              <label className="form-check-label" for="inlineCheckbox2">Ambassador</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" id="inlineCheckbox3" value="option1" />
              <label className="form-check-label" for="inlineCheckbox3">Cultural</label>
            </div>
            <div className="form-check form-check-inline">
              <input className="form-check-input" type="checkbox" id="inlineCheckbox4" value="option2" />
              <label className="form-check-label" for="inlineCheckbox4">Industry trend</label>
            </div>
          </div>
          <div className="row stack-box g-3">
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className="card card-stack"> <LazyImage image={{ src: stack_1, className: "bd-placeholder-img bd-placeholder-img-lg card-img", alt: "BeautifulPanoramicViewOfInfinityPoolWithOceanAndMountainsInTheBackground" }} />
                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                  <div className="stack-bt">
                    <h5 className="card-title">LAPONIA FOREVER</h5>
                    <p className="card-text">Andrea’s magical journey to Laponia</p>
                    <Link to={"/discover/article"} className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></Link> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-6">
              <div className="card card-stack"> <LazyImage image={{ src: stack_2, className: "bd-placeholder-img bd-placeholder-img-lg card-img", alt: "LuxuriousDesertRetreatWithPoolAndMountainView" }} />
                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                  <div className="stack-bt">
                    <img src={icon_computer_1} className="pb-2" alt="" />
                    <h5 className="card-title">TAKE A BREAK IN THE DESERT </h5>
                    <p className="card-text">“A surreal combination of extreme luxury and rugged wilderness.”<br />
                      Meet Vladislav’s Amangiri Hotel</p>
                    <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="card card-stack"> <img src={stack_3} className="bd-placeholder-img bd-placeholder-img-lg card-img" alt="WomanInRedShirtLookingOutFromGeodesicDomeWindowWithViewOfCountryside" />
                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                  <div className="stack-bt">
                    <h5 className="card-title">IN THE HEART OF NATURE</h5>
                    <p className="card-text">How did we get there? <br />
                      Find out our story</p>
                    <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6 col-xl-3">
              <div className="card card-stack"> <LazyImage image={{ src: stack_4, className: "bd-placeholder-img bd-placeholder-img-lg card-img", alt: "LuxuriousPoolAreaWithStoneWallAndPalmTrees" }} />
                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                  <div className="stack-bt">
                    <img src={icon_mask} className="pb-2" alt="" />
                    <h5 className="card-title">BENEFITS OF SWIMMING</h5>
                    <p className="card-text">Your stay, our passions.<br />
                      Read our mission for a <br />
                      new hospitality.</p>
                    <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="card card-stack"> <LazyImage image={{ src: stack_5, className: "bd-placeholder-img bd-placeholder-img-lg card-img", alt: "ModernHotelBedroomWithWoodenWallsAndFruitBowlOnTable" }} />
                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                  <div className="stack-bt">
                    <h5 className="card-title">SIMPLY LUXURY</h5>
                    <p className="card-text">Shhhhhhht...!<br />
                      Discover how we make your privacy our main preoccupation</p>
                    <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="card card-stack"> <LazyImage image={{ src: stack_6, className: "bd-placeholder-img bd-placeholder-img-lg card-img", alt: "BeautifulTurquoiseLakeWithBoatsAndMountainsInTheBackground" }} />
                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                  <div className="stack-bt">
                    <img src={icon_luggage} className="pb-2" alt="" />
                    <h5 className="card-title">YELLOWSTONE: MOTHER NATURE</h5>
                    <p className="card-text">Who are these newcomers in the national parks ?</p>
                    <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="card card-stack"> <LazyImage image={{ src: stack_7, className: "bd-placeholder-img bd-placeholder-img-lg card-img", alt: "PersonWithBackpackStandingOnRockyCliffOverlookingOceanAtSunset" }} />
                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                  <div className="stack-bt">
                    <h5 className="card-title">SKELLIG MICHAEL</h5>
                    <p className="card-text">Steep and rugged from another planet</p>
                    <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center carousel-btn"><img src={icon_charge} alt="" /></div>
        </div>
      </div>
    </>
  );
};
