const objRegistration = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    position: '',
    hotelName: '',
    city: '',
    website: '',
    subject: '',
    amadeus: '',
    message: '',
    hearAboutUs: '',
    companyName: ''
};
export const addRegistration = async (formRegistration, callback) => {
    // update missing fields here
    const updatedformRegistration = { ...formRegistration };

    for (const key in objRegistration) {
        if (!updatedformRegistration.hasOwnProperty(key)) {
            updatedformRegistration[key] = objRegistration[key];
        }
    }

    // const authToken = await getToken()
    fetch(`${process.env.REACT_APP_API_URL}/storefront/registration`, {
        headers: {
            'Content-Type': 'application/json',
            // Authorization: authToken,
        },
        method: 'POST',
        body: JSON.stringify(updatedformRegistration),
    })
        .then((response) => {

            if (!response.ok) {
                console.error(response)
            }
            return response.json()
        })
        .then((json) => {

            console.debug(json)
            callback(json)
        })
        .catch((error) => {
            console.error(error)
        })
}

