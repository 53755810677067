import '../../App.css'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import mhm_logo from '../../assets/logo-mhm.svg'
import linkedin_img from '../../assets/icons/icon-linkedin.svg'
import icon_twitter from '../../assets/icons/icon-twitter.svg'
import { useLocation } from 'react-router-dom';


export const Footer = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [open, setOpen] = useState(false)

  const location = useLocation();
  const [activeItem, setActiveItem] = useState('');

  const handleItemClick = (itemName) => {
    setActiveItem(itemName);
  };

  useEffect(() => {
    // Get the current pathname from the URL
    const currentPath = location.pathname;
    if (currentPath.includes('ourstory')) {
      setActiveItem('ourstory');
    }
    else if (currentPath.includes('discover')) {
      setActiveItem('discover');
    }
    else if (currentPath.includes('commitment')) {
      setActiveItem('commitment');
    }
    else if (currentPath.includes('contact')) {
      setActiveItem('contact');
    }
    else if (currentPath.includes('partner')) {
      setActiveItem('partner');
    }
    else if (currentPath.includes('onboarding')) {
      setActiveItem('onboarding');
    }
    else {
      setActiveItem('');
    }

  }, [location.pathname]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth)
      if (windowSize > 1024) {
        setOpen(false)
      }
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md text-center"> <a href="/" className="text-decoration-none"> <img src={mhm_logo} className="footer-logo" alt="" /> </a> </div>
          <div className="col-6 col-md">
            <ul className="nav flex-column">
              <li className="nav-item"><Link to="/ourstory" className={activeItem === 'ourstory' ? 'nav-link text-red' : 'nav-link'}>Our story</Link></li>
              {/* <li className="nav-item"><Link to="/discover" className={activeItem === 'discover' ? 'nav-link text-red' : 'nav-link'}>Discover</Link></li> */}
              <li className="nav-item"><Link to="/commitment" className={activeItem === 'commitment' ? 'nav-link text-red' : 'nav-link'}>Commitments</Link></li>
              <li className="nav-item"><Link to="/contact" className={activeItem === 'contact' ? 'nav-link text-red' : 'nav-link'}>Contact</Link></li>
            </ul>
          </div>
          <div className="col-6 col-md">
            <ul className="nav flex-column">
              <li className="nav-item"><Link to={"/partner"} className={activeItem === 'partner' ? 'nav-link text-red' : 'nav-link'}>Become a partner</Link></li>
              <li className="nav-item"><Link to={"https://mymatchingcompany.com"} target='_blank' className="nav-link">Invest</Link></li>
              <li className="nav-item"><Link to={"/"} className="nav-link">Legal notices</Link></li>
              <li className="nav-item"><Link to={"/"} className="nav-link">Terms of service</Link></li>
            </ul>
          </div>
          <div className="col-6 col-md mob-mar-30">
            {/* <ul className="nav flex-column">
              <li className="nav-item"><Link to={"#"} className="nav-link">Documentations</Link></li>
              <li className="nav-item"><Link to={"/onboarding"} className={activeItem === 'onboarding' ? 'nav-link text-red' : 'nav-link  text-red'}>Onboarding</Link></li>
              <li className="nav-item"><Link to={"#"} className="nav-link text-red">Matching Guide</Link></li>
            </ul> */}
          </div>
          <div className="col-6 col-md mob-mar-30">
            <h5 className="follow-title">follow us </h5>
            <ul className="list-unstyled d-flex justify-content-center">
              <li> <Link className="link-body-emphasis" to={"https://www.linkedin.com/company/myhotelmatch/"} target='_blank'> <img src={linkedin_img} alt="" /> </Link></li>
              <li class="ms-2"> <Link class="link-body-emphasis" to={"https://twitter.com/my_hotel_match"} target='_blank'> <img src={icon_twitter} alt="" /> </Link></li>
            </ul>
          </div>
        </div>
        <p className="second-footer">©MyHotelMatch2023 <strong class="float-md-end"><i><Link to={"http://twid-design.com/"} target="_blank">Design Twid</Link></i></strong></p>
      </div>
    </footer>
  )
}
