import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { LazyImage } from '../components/LazyImage/LazyImage'

import Apple from '../assets/Apple.svg';
import Google from '../assets/Google.svg';

import right_arrow from "../assets/icons/right-arrow.svg";
import story_banner from "../assets/TranquilTropicalParadiseWithRelaxingWomanOnBoat.png";
import user_3 from "../assets/user-3.png";
import user_3_2 from "../assets/YvesAbitbolJeanFrancoisOttJoelHikingKilimandjaro.png";
import user_3_3 from "../assets/MyHotelMatchTeamGatheringInOffice.png";
import icon_cross_btn from "../assets/icons/icon-cross-btn.svg";
import icon_heart_btn from "../assets/icons/icon-heart-btn.svg";

import story_12 from "../assets/thumbnail/PositiveInteractionHandshakeInBrightAmbience.png";
import story_13 from "../assets/thumbnail/story_13.png";
import story_14 from "../assets/thumbnail/SereneSunsetViewFromDock.png";
import story_15 from "../assets/thumbnail/story_15.png";

import icon_block_plane from "../assets/icons/icon-block-plane.svg";
import icon_honest from "../assets/icons/icon-honest.svg";
import icon_brain from "../assets/icons/icon-brain.svg";
import icon_diamond from "../assets/icons/icon-diamond.svg";
import icon_sun from "../assets/icons/icon-sun.svg";

export const OurStory = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })


    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>You Were Made To Explore Horizons - MyHotelMatch</title>
                <link rel="canonical" href="https://myhotelmatch.com/ourstory" />
                <meta name="description" content="Explore MyHotelMatch Story and relive unforgettable moments. Discover how a perfect hotel match can elevate your travel experience." />
                <meta name="keywords" content="Hotel, luxury, tailor-made, stay, Boutique accommodations, Lavish lodging, Exclusive retreat, Personalized escape, Qualitative hotel, Customized stay, Tailored luxury, Bespoke hospitality, Handcrafted experience, High-end residence, Deluxe suite, Unique lodging, Tailor-made amenities, Luxury accommodations, Tailored hospitality, Luxury retreat, Tailored comfort, Premium stay, Customized lodging, VIP hotel, Luxury getaway, Tailor-made service, Upscale accommodations, Tailored vacation, Luxury oasis" />
            </Helmet>

            <div className="main-content">
                {/* <div className="banner">
                <div className="video-container story-bn"> <img src={story_banner} className="img-fluid story-bn-img" alt="" />
                    <div className="overlay-content">
                        <div className="hero-text hero-text-ourstory">
                            <h1>YOU WERE MADE</h1>
                            <h1 className="left-space left-space-disable p-0">TO EXPL<span className="star">O</span>RE </h1>
                        </div>
                    </div>

                </div>
            </div> */}
                <div className="banner">
                    <div className="video-container story-bn"> <LazyImage image={{ src: story_banner, className: "img-fluid story-bn-img", alt: "WomanInWhiteBlouseRelaxingOnBoatInBlueSeaWithTropicalIslandsInBackground" }} />
                        <div className="overlay-content">
                            <div className="hero-text">
                                <h1>YOU WERE MADE</h1>
                                <h1 className="left-space p-0">TO  EXPL<span className="star">O</span>RE </h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container phone-bg-cont" style={{ display: 'none' }}>
                    <div className="row row-cols-1 row-cols-lg-3 g-4">
                        <div className="col">
                            <div className="card h-100 story-card">
                                <div className="card-body">
                                    <h5 className="card-title"><img src={user_3} className="img-circl" alt="OrangeCircl" /><span className="ms-4">Disappointed</span></h5>
                                    <p className="home-p mb-4">Jean-François spent the last 15 years travelling more than staying at home.
                                        He loves it.</p>
                                    <p className="home-p"> But let's be honest, it's not always fairytales and rainbows. Broken shutter, loud neighbourhood, slumped mattress, off-center location. Some good, some bad, you can't predict everything. Until he arrives at a love hotel as a solo business traveler. Or in a partying hostel as a family with tired and young childrens. Imagine doing the check out at midnight and going back to your car, with your kid in your arms.<br />
                                        Would you be resigned? </p>
                                </div>
                                <div className="card-footer text-end"> <a className="icon-link" href="#"> <img src={icon_cross_btn} alt="OrangeCross" /></a> <a className="icon-link ms-5" href="#"> <img src={icon_heart_btn} alt="OrangeHeart" /></a> </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 story-card">
                                <div className="card-body">
                                    <h5 className="card-title"><img src={user_3_2} className="img-circl" alt="YvesAbitbolJeanFrancoisOttJoelHikingKilimandjaro" /><span className="ms-4">Inspired</span></h5>
                                    <p className="home-p">We know its clichée, but MyHotelMatch begins on the top of the Kilimandjaro.
                                        It's a story about efforts, friendship and enlightenment. Yves is special. He is the type that makes sh*t happens. "Could you organize us the ascension of the tallest and hardest peak in Africa? <br />
                                        Yes.”<br />
                                        Despite the wind, the cold and the sweat, the experience was seamless. Amongst those who shared the same dream, they each lived something very unique to themselves. <br />

                                        JF had the proof he needed that another way of travelling was possible. </p>
                                </div>
                                <div className="card-footer text-end"> <a className="icon-link" href="#"> <img src={icon_cross_btn} alt="OrangeCross" /></a> <a className="icon-link ms-5" href="#"> <img src={icon_heart_btn} alt="OrangeHeart" /></a> </div>
                            </div>
                        </div>
                        <div className="col">
                            <div className="card h-100 story-card">
                                <div className="card-body">
                                    <h5 className="card-title"><img src={user_3_3} className="img-circl" alt="MyHotelMatchTeamSittingTogetherInOffice" /><span className="ms-4">Ideas + action = realisation.</span></h5>
                                    <p className="home-p mb-2">Seems easy? Jean-François called once again his new-old friend, this time to create MyHotelMatch. An app that brings together travelers with a vision and hotels managers like-minded. Don’t think everything went smooth this time : an app is no simple trip. They had some hard times, doubts, and downs. But, perseverance never left. <br />
                                        After a lot of phone calls, excels sheets and figma links, MHM has now offices, technologies and an app. Most importantly, they have coworkers that now share their initial dream. With Nina, Neil, Marco and more than 8000 partners, travelers & hotels said "yes" to each others. :)
                                    </p>
                                </div>
                                <div className="card-footer text-end"> <a className="icon-link" href="#"> <img src={icon_cross_btn} alt="OrangeCross" /></a> <a className="icon-link ms-5" href="#"> <img src={icon_heart_btn} alt="OrangeHeart" /></a> </div>
                            </div>
                        </div>


                    </div>
                    <div className="text-center pt-5">
                        <Link to={"/contact"} className="btn btn-dark orange-btn mt-5">Download the app</Link>
                    </div>
                </div>
                <div className="half-curve-cont">
                    <div className="container">
                        <p className="title-sm">OUR MISSION:</p>
                        <div className="d-flex justify-content-center mb-3">
                            <h3 className="title-sub orange-txt shuffle-txt tex-big ipad-text-cent mob-text-cent"><span className="ms-5">REINVENT </span>
                                <div className="sub p-0 mt-3 line-sm">the <span className="art-text">limits</span> of travelling <img src={icon_block_plane} alt="OrangePlane" className="circle-plane" /></div>
                            </h3>
                        </div>
                        <p className="home-p text-center mb-3 home-p-ourstory">It's by telling us who you are that<br />
                            we can get you the offer best suiting</p>
                        <div className="row arrow-top-lg-r">
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                <figure className="figure stack-thumb story-stack col-md-3"> <LazyImage image={{ src: story_12, className: "figure-img img-fluid", alt: "FriendlyHandshakeBetweenTwoPeopleInBrightRoom" }} />
                                    <figcaption className="figure-caption text-center">
                                        <div className="text-end"><img src={icon_honest} alt="OrangeHeart" /></div>
                                        <h5 className="card-title text-center">HONESTY</h5>
                                        <p className="orange-p text-center">Admit failure but don't underestimate your strengths</p>
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                <figure className="figure stack-thumb story-stack col-md-3 video-1">
                                    <video autoPlay loop muted playsInline>
                                        <source src="https://storage.googleapis.com/mhm-medias/store-front/video-6.mp4" type="video/mp4" />
                                        Your browser does not support the video tag. </video>
                                    <figcaption className="figure-caption text-center">
                                        <div className="text-end"><img src={icon_brain} alt="OrangeBrain" /></div>
                                        <h5 className="card-title text-center">OPENNESS</h5>
                                        <p className="orange-p text-center">Keep your mind <br />
                                            fresh</p>
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                <figure className="figure stack-thumb story-stack col-md-3"> <LazyImage image={{ src: story_14, className: "figure-img img-fluid", alt: "PersonInWhiteSweaterSittingOnDockObservingSunsetOverCalmOcean" }} />
                                    <figcaption className="figure-caption  text-center">
                                        <h5 className="card-title text-center">UNIQUENESS</h5>
                                        <p className="orange-p text-center d-flex diamond-pos"><span className="flex-grow-1">Don’t be like<br />
                                            anyone else</span> <img src={icon_diamond} alt="OrangeDiamond" /></p>
                                    </figcaption>
                                </figure>
                            </div>
                            <div className="col-sm-6 col-md-6 col-lg-6 col-xl-3">
                                <figure className="figure stack-thumb story-stack col-md-3">
                                    <video autoPlay loop muted playsInline>
                                        <source src="https://storage.googleapis.com/mhm-medias/store-front/video-7.mp4" type="video/mp4" />
                                        Your browser does not support the video tag. </video>
                                    <figcaption className="figure-caption text-center">
                                        <div className="text-center"><img src={icon_sun} alt="OrangeSun" /></div>
                                        <h5 className="card-title text-center">ENTHUSIASM</h5>
                                        <p className="orange-p text-center">Allow yourself to meet the unknown</p>
                                    </figcaption>
                                </figure>
                            </div>
                        </div>
                        <div className="text-center mt-5">
                            <Link to={"https://play.google.com/store/apps/details?id=com.mhmlabs.myhotelmatch"} target="_blank"><img src={Google} alt="Google" style={{ margin: "0 10px" }} /></Link>
                            <Link to={"https://apps.apple.com/fr/app/myhotelmatch/id6451199349?l=en-GB"} target="_blank"><img src={Apple} alt="Apple" style={{ margin: "0 10px" }} /></Link>
                        </div>
                    </div>
                </div>
                <div className="ambition-cont">
                    <div className="container mob-text-cent">
                        <p className="title-sm">OUR AMBITION:</p>
                        <div className="d-flex justify-content-center mb-3">
                            <h3 className="title-sub orange-txt shuffle-txt tex-big  mb-5"><span className="ms-5">BECOME </span>
                                <div className="sub p-0 mt-3"> the <span className="art-text">cool</span> </div>
                                <div className="sub text-end circle">alternative </div>
                            </h3>
                        </div>
                        <h3 className="title-sub pb-3 mob-text-cent">A letter by Jean-François Ott</h3>
                        <p className="sub-txt">Executive Chairman MYHOTELMATCH</p>
                        <div className="line-wing">
                            <p className="home-p quote mb-3"> This matching project has been maturing in me for several years, through my activity in the hotel industry, being the owner of several hotel establishments myself and having the desire to defend this profession beyond borders by giving it back power, but also from my spirit as an entrepreneur, constantly traveling the world and spending 200 nights a year in a hotel as a traveler. By creating MYHOTELMATCH and with the integration of MY AGENCY into our project, we will modernize and transform the travel booking experience.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
