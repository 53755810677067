import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import Apple from '../assets/Apple.svg';
import Google from '../assets/Google.svg';

import icon_reg from "../assets/icons/icon-reg.svg";
import img12 from "../assets/SmartphoneAndLaptop.png";
import img13 from "../assets/ExpressingLoveThroughDigitalMessage.png";
import img14 from "../assets/SunlitPickleballMatchOnOutdoorCourt.png";
import img15 from "../assets/FocusedWorkWithRedPen.png";
import icon_hotel from "../assets/icons/hotel.svg";
import icon_star_outine from "../assets/icons/icon-star-outine.svg";

import { LazyImage } from '../components/LazyImage/LazyImage'




export const OnBoarding = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MyHotelMatch</title>
                <link rel="canonical" href="https://myhotelmatch.com/onboarding" />
            </Helmet>

            <div className="main-content">
                <div className="main-title">
                    <div className="d-flex justify-content-center mb-3">
                        <h3 className="title-sub orange-txt shuffle-txt tex-big  mb-5"><span className="ms-5">GREETINGS, </span>
                            <div className="sub p-0 mt-3 line-sm-1"> would you like to get <span className="art-text">started?</span> </div>
                        </h3>
                    </div>
                    <div className="container">
                        <p className="title-p">MyHotelMatch is a platform that enables hoteliers to connect with guests who are a perfect match for their establishment. Thanks to our intelligent algorithm, we match you with travelers who share your values and interests.</p>
                        <p className="title-p">MyHotelMatch is a powerful tool that can help you increase your bookings and improve your customer experience. By following our tips, you'll be well on your way to success.</p>
                    </div>
                </div>
                <div className="container">
                    <div className="d-flex align-items-center mb-5 mob-blck ipad-blck">
                        <div className="flex-shrink-0 col-sm-12 col-md-4 mb-5">
                            <div className="img-container-sm"> <LazyImage image={{ src: img12, className: "img-fluid", alt: "CloseUpOfHandsHoldingSmartphoneWithLaptopAndPlantInTheBackground" }} /> <span className="click-line">click</span> </div>
                        </div>
                        <div className="flex-grow-1 col-sm-12 col-md-8 mb-5">
                            <div className="ms-5">
                                <h3 className="title-sub list-titl pb-3"> <span className="numb">1</span> <span>REGISTRATION <br />
                                    AND ACCOUNT</span> <img src={icon_reg} className="ms-4" alt="" /></h3>
                                <p className="home-p ms-3">Download the application and create your account in just a few clicks.<br />
                                    Create your Professional Profile.</p>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex mb-5 pt-5 mob-blck ipad-blck">
                        <div className="flex-shrink-0 col-md-6 mb-5">
                            <h3 className="title-sub list-titl pb-3"> <span className="numb">2</span> <span>CHOOSE A PROFESSIONAL<br />
                                USERNAME</span></h3>
                            <p className="home-p ms-3">Honestly, share your human qualities as a host.<br />
                                Your Hotel Profile.</p>
                        </div>
                        <div className="flex-grow-1">
                            <div className="ms-5 left-arrow-line">
                                <h3 className="title-sub list-titl pb-3"> <span className="numb">3</span> <span>JOIN OR CREATE<br />
                                    YOUR HOTEL’S PROFILE </span></h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <div className="d-flex align-items-center mb-5 mob-blck ipad-blck">
                        <div className="flex-grow-1  mb-5">
                            <div className="ms-50">
                                <h3 className="title-sub list-titl pb-3"> <span className="numb">4</span> <span>HOTELIER <br />
                                    DASHBOARD</span> </h3>
                                <p className="home-p ms-3">View your dashboard including:</p>
                                <ul className="p-list ms-3">
                                    <li>Ranking of your matchmakers</li>
                                    <li>Ranking of your hotel among your competitors</li>
                                    <li>Generated revenues, statistics and graphs</li>
                                </ul>
                                {/* <p className="home-p ms-3">Match and Conversations.</p> */}
                            </div>
                        </div>
                        <div className="flex-shrink-0 ms-5  col-md-4">
                            <div className="img-container-sm border-rad-half"> <LazyImage image={{ src: img13, className: "img-fluid", alt: "ManInBlueSweaterHoldingPhoneWithHeartSpeechBubble" }} /></div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="d-flex mb-5 pt-5 mob-blck ipad-blck">
                        <div className="flex-shrink-0  mb-5 col-md-6">
                            <h3 className="title-sub list-titl pb-3 env-arrow"> <span className="numb">5</span> <span>ALL YOUR MATCHES<br />
                                AND DISCUSSIONS <br />
                                GROUPED TOGETHER</span></h3>
                            <p className="home-p ms-3">Ranked by recency, priority or arrival date.<br />
                                Send personalized messages to your matches.<br />
                                Archive and Lessons <br />
                                Explore old conversations for lessons learned.</p>
                        </div>
                        <div className="flex-grow-1  mb-5">
                            <div className="ms-5 pb-5 mb-5"> <img src={icon_hotel} alt="" className="float-end mob-disp-none" />
                                <h3 className="title-sub list-titl pb-3"> <span className="numb">6</span> <span>JOIN OR CREATE<br />
                                    YOUR HOTEL’S PROFILE </span></h3>
                                <p className="home-p ms-3">Complete the characteristics of your hotel.<br />
                                    Identify preferred communities of travelers.</p>
                            </div>
                            <div className="ms-5">
                                <h3 className="title-sub list-titl pb-3 icon-valid"> <span className="numb">7</span> <span>CONFIRMED <br />
                                    RESERVATIONS</span></h3>
                                <p className="home-p ms-3">View booking details.<br />
                                    Leave comments and reviews in the archives.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="yellow-bg">
                    <div className="container">
                        <p className="title-sm">Here are a few tips to help you get the most out of MyHotelMatch</p>
                        <div className="d-flex align-items-center mt-5 mb-5 mob-blck ipad-blck">
                            <div className="flex-shrink-0 has-arrow-2"> <LazyImage image={{ src: img14, className: "img-fluid", alt: "TwoPeoplePlayingPickleballOnOutdoorCourtWithSunshine" }} /> </div>
                            <div className="flex-grow-1 ms-5">
                                <h3 className="title-sub pb-3 orange-txt">BE ACTIVE</h3>
                                <p className="home-p">The more you interact with travelers, the more likely you are<br />
                                    to convert them into customers. <br />
                                    Don't hesitate to answer their questions <br />
                                    and offer them special deals.</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-5 mob-blck ipad-blck">
                            <div className="flex-grow-1 text-end">
                                <h3 className="title-sub pb-3 orange-txt">BE AUTHENTIC</h3>
                                <p className="home-p mb-4">Travelers look for hotels that are authentic and reflect the personality<br />
                                    of their owners. Be yourself and show travelers why your<br />
                                    establishment is unique.<br />
                                    The deepest secret to success in our business is transparency,<br />
                                    honesty and sincerity. Be yourself and show travelers that you care.</p>
                            </div>
                            <div className="flex-shrink-0 ms-5 has-cloud"> <LazyImage image={{ src: img15, className: "img-fluid", alt: "WomanHoldingRedPen" }} /> </div>
                        </div>
                    </div>
                </div>

                <div className="container pt-5 mt-5">
                    <div className="article-about-cont">
                        <div className="text-center">
                            <h3 className="title-sub tex-big orange-txt text-center pb-1">WHAT ABOUT Y<img src={icon_star_outine} alt="" className="pad-bt-10" />u?</h3>
                            <p>Live your own unique story!</p>
                            <div className="text-center mobile-crd-text">
                                <Link to={"https://play.google.com/store/apps/details?id=com.mhmlabs.myhotelmatch"} target="_blank"><img src={Google} alt="Google" style={{ margin: "0 10px" }} /></Link>
                                <Link to={"https://apps.apple.com/fr/app/myhotelmatch/id6451199349?l=en-GB"} target="_blank"><img src={Apple} alt="Apple" style={{ margin: "0 10px" }} /></Link>
                            </div>
                        </div>
                    </div>
                </div>



            </div>
        </>
    );
};
