import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import { LazyImage } from '../components/LazyImage/LazyImage'

import Apple from '../assets/Apple.svg';
import Google from '../assets/Google.svg';

import right_arrow from "../assets/icons/right-arrow.svg";
import time_precious_img1 from "../assets/time_precious_img1.png";
import time_precious_img2 from "../assets/VibrantJourneyWomanInColorfulAlleyway.png";
import time_precious_img3 from "../assets/LuxuryHotelPatio.png";
import time_precious_img4 from "../assets/InfinityPool.png";

//for image slider thumbnail section
import icon_heart_line from "../assets/icons/icon-heart-line.svg";

import home_1 from "../assets/thumbnail/BeautifulBluePoolWithLoungeChairsAndUmbrella.png";
import home_2 from "../assets/thumbnail/BusyNewYorkCityStreetWithYellowCabs.png";
import home_3 from "../assets/thumbnail/PeacefulViewOfTajMahalFromDistance.png";
import home_4 from "../assets/thumbnail/MoodyJapaneseStreetSceneWithUmbrellas.png";
import home_5 from "../assets/thumbnail/BergenNorwayColorfulWaterfrontHousesMountainBackground.png";
import home_6 from "../assets/thumbnail/CinqueTerreItalyColorfulHousesOnCliffOverlookingMediterraneanSea.png";
import home_7 from "../assets/thumbnail/AerialViewCanyonPersonWalkingSandForeground.png";

import icon_camera_bloc from "../assets/icons/icon-camera-bloc.svg";
import phone_1 from "../assets/MyHotelMatchBookingBestOffer.png";
import phone_2 from "../assets/phone-2.png";
import phone_3 from "../assets/WineEstateTextProposal.png";
import phone_4 from "../assets/HotelReservationConfirmationPrague.png";

// import stack_1 from "../assets/stack/stack-1.png";
// import stack_2 from "../assets/stack/stack-2.png";
// import stack_3 from "../assets/stack/stack-3.png";
// import stack_4 from "../assets/stack/stack-4.png";
// import stack_5 from "../assets/stack/stack-5.png";
// import stack_6 from "../assets/stack/stack-6.png";
// import stack_7 from "../assets/stack/stack-7.png";

import icon_arrow_right_orange from "../assets/icons/icon-arrow-right-orange.svg";
import icon_star_outine from "../assets/icons/icon-star-outine.svg";
import phone_arrow_icon from "../assets/icons/phone-arrow-icon.svg";
import icon_sound from "../assets/icons/icon-sound.svg";
import icon_computer_1 from "../assets/icons/icon-computer-1.svg";
import icon_mask from "../assets/icons/icon-mask.svg";
import icon_arrow_down from "../assets/icons/icon-arrow-down.svg";
import icon_arrow_7 from "../assets/icons/icon-arrow-7.svg";

import "../css/photo-glider.css";
import "glider-js/glider.min.css";


// import React from "react";
import PhotosCarousel from "../components/PhotosCarousel/PhotosCarousel.js";


const photosToShow = [
    { url: home_1, likes_count: "2590", alt_text: "LuxuriousPoolAreaWithWhiteUmbrellaAndLoungeChairs" },
    { url: home_2, likes_count: "1548", alt_text: "NewYorkCityStreetViewWithYellowCabsAndPedestrians" },
    { url: home_3, likes_count: "1250", alt_text: "TajMahalViewFromDistanceWithPeopleInForeground" },
    { url: home_4, likes_count: "2590", alt_text: "TwoPeopleWalkingInJapaneseStreetWithUmbrellas" },
    { url: home_5, likes_count: "3120", alt_text: "BergenNorwayColorfulWaterfrontHousesWithMountainBackground" },
    { url: home_6, likes_count: "899", alt_text: "ColorfulHousesOnCliffOverlookingMediterraneanSeaInCinqueTerreItaly" },
    { url: home_7, likes_count: "1400", alt_text: "AerialViewOfCanyonWithPersonWalkingOnSandInForeground" },
];

const thumbnailsCarouselConfig = {
    itemWidth: 250,
    slidesToShow: "auto",
    slidesToScroll: "auto"
};


export const Home = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [currentPhotoIdx, setCurrentPhotoIdx] = React.useState(0);
    const onClickPhotoHandler = React.useCallback(
        (idx) => () => setCurrentPhotoIdx(idx),
        []
    );
    const onSlideChangeHandler = React.useCallback(
        (photoIdx) => {
            setCurrentPhotoIdx(photoIdx);
        },
        [setCurrentPhotoIdx]
    );
    const photos = React.useMemo(
        () =>
            photosToShow.map((objPhotoUrl, idx) => {
                const key = `${objPhotoUrl.url}_${idx}`;
                let className = "slide__content thumb";
                if (currentPhotoIdx === idx) {
                    className += " --current-selected";
                }
                return (
                    <div
                        key={key}
                        className={className}
                        onClick={onClickPhotoHandler(idx)}
                    >
                        <img alt={objPhotoUrl.alt_text} src={objPhotoUrl.url} />
                        <span className="count-numb">{objPhotoUrl.likes_count} <img src={icon_heart_line} alt="heart" /></span>
                        <figcaption className="figure-caption"></figcaption>
                    </div>
                );
            }),
        [onClickPhotoHandler, currentPhotoIdx]
    );

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    const linkStyle = {
        cursor: 'pointer'
    };

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Stay in the Place You Love - MyHotelMatch</title>
                <link rel="canonical" href="https://myhotelmatch.com" />
                <meta name="description" content="Your ideal getaway is just a click away. Explore top hotels with MyHotelMatch and book the perfect stay." />
                <meta name="keywords" content="Hotel, luxury, tailor-made, stay, Boutique accommodations, Lavish lodging, Exclusive retreat, Personalized escape, Qualitative hotel, Customized stay, Tailored luxury, Bespoke hospitality, Handcrafted experience, High-end residence, Deluxe suite, Unique lodging, Tailor-made amenities, Luxury accommodations, Tailored hospitality, Luxury retreat, Tailored comfort, Premium stay, Customized lodging, VIP hotel, Luxury getaway, Tailor-made service, Upscale accommodations, Tailored vacation, Luxury oasis" />
            </Helmet>
            <div className="main-content">
                <div className="main-banner">
                    <div className="video-container">
                        <video width="100%" autoPlay loop muted playsInline>
                            <source src="https://storage.googleapis.com/mhm-medias/store-front/banner-video.mp4" type="video/mp4" />
                            Your browser does not support the video tag. </video>
                        <div className="overlay-content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-7 col-lg-7">
                                        <div className="hero-text">
                                            <h1>STAY </h1>
                                            <h1 className="left-space"> AT THE PLA<span className="star">C</span>E</h1>
                                            <h2 className="left-space">you <span>love</span></h2>
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-lg-5">
                                        <div className="banner-card-cont float-md-end">
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">Join our hotels community?</h5>
                                                    <Link to={"/partner"} className="btn btn-dark orange-btn">Become a partner <img src={right_arrow} alt="OrangeArrow" /></Link> </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <h5 className="card-title">Want to become an investor?</h5>
                                                    <Link to={"https://mymatchingcompany.com/"} target='_blank' className="btn btn-dark orange-btn">Get into it <img src={right_arrow} alt="OrangeArrow" /></Link> </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link style={linkStyle} onClick={() => scrollToSection('sectionTimePrecious')} className="banner-arrow"><img src={icon_arrow_down} alt="DownArrow" /></Link> </div>
                    </div>
                </div>
                <div className="container">
                    <div className="card phone-banner">
                        <div className="row g-0">
                            <div className="col-md-6">
                                <div className="card-body">
                                    <h5 className="card-title">MyHotelMatch app brings together passionate hoteliers and discerning customers to create </h5>
                                    <p className="card-text">bespoke-stays.</p>
                                </div>
                            </div>
                            <div className="col-md-6 phone-bg"> </div>
                        </div>
                    </div>
                </div>
                <div id="sectionTimePrecious" className="d-flex mob-blck">
                    <div className="flex-shrink-0">
                        <div className="half-video">
                            <video autoPlay loop muted playsInline>
                                <source src="https://storage.googleapis.com/mhm-medias/store-front/video-3.mp4" type="video/mp4" />
                                Your browser does not support the video tag. </video>
                        </div>
                    </div>
                    <div className="flex-grow-1 content-home-1 mob-text-cent">
                        <h3 className="title-sub has-arrow-1">Your <span className="art-text">time</span>
                            <div className="sub">IS PRECIOUS</div>
                        </h3>
                        <div className="content-pad-1">
                            <p className="home-p">In the grand symphony of life, time is the most exquisite note. </p>
                            <p className="home-p mb-5">At MyHotelMatch, we understand that your time is a treasure too valuable to squander. Luxury is not found in the extravagant, but in the art of savoring.It's the sublime comfort of a well-chosen hotel that resonate with your dreams.</p>
                            <Link to={"/ourstory"} className="btn btn-dark orange-btn">Check our story <img src={right_arrow} alt="OrangeArrow" /></Link> </div>
                    </div>
                    <div className="flex-shrink-0 pad-tp-18">
                        <div className="waiting-f-u"> <LazyImage image={{ src: time_precious_img3, className: "content-img-rit", alt: "A White Patio Overlooking The Ocean, Two Wooden Deckchairs With Blue and White Striped Cushions, A Table With Wine Glasses and Potted Plants." }} /> <span className="waitng-txt"> waiting<br />
                            for you <img src={icon_arrow_7} alt="OrangeArrow" /> </span> </div>
                    </div>
                </div>
                <div className="d-flex mb-5 mob-blck">
                    <div className="flex-shrink-0 merge-pic">
                        <div className="merg-img"> <LazyImage image={{ src: time_precious_img2, alt: "WomanInStrawHatWalkingThroughColorfulAlleyway" }} /> </div>
                    </div>
                    <div className="flex-grow-1 content-home-2">
                        <div className="float-md-end">
                            <h3 className="title-sub">HOSPITALITY
                                <div className="sub px-3">IS IN THE <span className="art-text">details</span></div>
                            </h3>
                        </div>
                        <div className="clearfix"></div>
                        <div className="mob-text-cent">
                            <p className="home-p mb-5">Where every aspect of your hotel operation embodies the highest standards of excellence. Your guest will live the warm and knowledgeable demeanor of a manager who understands their passion and guides them to unforgettable guest experiences. It's the flawless coordination of your staff working in perfect harmony. <br />
                                MyHotelMatch highlights these exceptional standards. We select partners known for their steadfast dedication to delivering exceptional service as a way of life..</p>
                            <Link to={"/partner"} className="btn btn-dark orange-btn">Want to become our partner <img src={right_arrow} alt="OrangeArrow" /></Link> </div>
                    </div>
                </div>
                <div className="d-flex mob-blck">
                    <div className="flex-grow-1 content-home-3 mob-text-cent">
                        <h3 className="title-sub">YOUR JOURNEY BECOMES
                            <div className="sub">MEMORABLE <span className="art-text">experience</span></div>
                        </h3>
                        <div className="clearfix"></div>
                        <div className="content-pad-2">
                            <p className="home-p mb-5">When it comes to traveling, there’s a rare and exquisite moment when the stars align, and your very essence resonates with the place you call home during your sojourn. It's the sensation of stepping into your favorite room, where every detail is in harmony. These are places where you become part of the story, where every stay is an opportunity to create memories that will remain with you forever.</p>
                            <Link to={"/contact"} className="btn btn-dark orange-btn">Start the experience <img src={right_arrow} alt="OrangeArrow" /></Link> </div>
                    </div>
                    <div className="flex-shrink-0">
                        <div className="overflow-hide-mob"> <LazyImage image={{ src: time_precious_img4, className: "pos-right-three", alt: "A Man Sitting On The Edge Of An Infinity Pool Surrounded By A Green Forest." }} /> </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid orange-container">
                <div className="d-flex justify-content-center">
                    <h3 className="title-big white-txt">IT MAKES <img src={icon_sound} className="align-bottom" alt="OrangeSpeaker" />
                        <div className="sub">a lot of <span className="art-text">noise!</span></div>
                    </h3>
                </div>
                <div className="carousel-container mb-5">
                    <div className="App">
                        <div className="photos-gallery">

                            <PhotosCarousel
                                className="thumbnails-wrapper"
                                config={thumbnailsCarouselConfig}
                                currentSlideIdx={currentPhotoIdx}
                            >
                                {photos}
                            </PhotosCarousel>
                        </div>
                    </div>

                </div>
                <div className="text-center "> <Link to={"/contact"} className="btn btn-dark white-btn btn-with-star">Join our community <img src={icon_arrow_right_orange} alt="" /></Link> </div>
            </div>
            <div className="container">
                <div className="mobile-thumb-cont">
                    <div className="d-flex justify-content-center">
                        <h3 className="title-big center-txt"><span className="orange-txt">A tailor-made </span><img src={icon_camera_bloc} alt="OrangeCameraBloc" />
                            <div className="sub">experience just for you </div>
                        </h3>
                    </div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card mobile-thumb-card">
                                <div className="card-header"> Get perfectly fitting <br />
                                    hotel proposals</div>
                                <div className="card-body lines-top"> <LazyImage image={{ src: phone_1, className: "card-img-top", alt: "An IPhone Showing MyHotelMatch Booking App With A Special Offer For Hotel Barriere Fouquet's New York, Featuring The Hotel's Pool And Skyline." }} /> </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card mobile-thumb-card">
                                <div className="card-header">Bispeak your travel <br />
                                    <span className="arrow-down-cv"> with your host </span></div>
                                <div className="card-body"> <LazyImage image={{ src: phone_3, className: "card-img-top", alt: "A Screenshot Of A Text Conversation On An IPhone Between Anna and Harry, Who Are Discussing A Visit To A Wine Estate." }} /> </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card mobile-thumb-card">
                                <div className="card-header"> Live a one time<br />
                                    in life journey</div>
                                <div className="card-body lines-bottom"> <LazyImage image={{ src: phone_4, className: "card-img-top", alt: "An IPhone Showing My Hotel Match Booking App With An Illustration Of A Suitcase With Text Confirming A Hotel Reservation In Prague." }} /> </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mobile-crd-text">
                        <Link to={"https://play.google.com/store/apps/details?id=com.mhmlabs.myhotelmatch"} target="_blank"><img src={Google} alt="Google" style={{ margin: "0 10px" }} /></Link>
                        <Link to={"https://apps.apple.com/fr/app/myhotelmatch/id6451199349?l=en-GB"} target="_blank"><img src={Apple} alt="Apple" style={{ margin: "0 10px" }} /></Link>
                        <p>Hey, all your data remains confidential. </p>
                    </div>
                </div>
            </div >
            {/* <div className="yellow-bg-nibble">
                <div className="container">
                    <div className="d-flex justify-content-center">
                        <h3 className="title-big shuffle-txt"><span className="orange-txt">NIBBLING</span>
                            <div className="sub">the <span className="art-text globe">world</span></div>
                        </h3>
                    </div>
                    <div className="row g-3">
                        <div className="col-md-6 col-lg-6 col-xl-6">
                            <div className="card card-stack"> <img src="images/stack/stack-1.png" className="bd-placeholder-img bd-placeholder-img-lg card-img" alt="" />
                                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                                    <div className="stack-bt">
                                        <h5 className="card-title">Laponia forever</h5>
                                        <p className="card-text">Andrea's magical journey to Laponia</p>
                                        <Link href="#" className="btn-orange-link">Learn more <img src="images/icons/icon-arrow-right-orange.svg" alt="" /></Link> </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-6">
                            <div className="card card-stack"> <img src="images/stack/stack-2.png" className="bd-placeholder-img bd-placeholder-img-lg card-img" alt="" />
                                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                                    <div className="stack-bt"> <img src="images/icons/icon-computer-1.svg" className="pb-2" alt="" />
                                        <h5 className="card-title">TAKE A BREAK IN THE DESERT </h5>
                                        <p className="card-text">"A surreal combination of extreme luxury and rugged wilderness." <br />
                                            Meet Vladislav's Amangiri Hotel</p>
                                        <Link href="#" className="btn-orange-link">Learn more <img src="images/icons/icon-arrow-right-orange.svg" alt="" /></Link> </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="card card-stack"> <img src="images/stack/stack-3.png" className="bd-placeholder-img bd-placeholder-img-lg card-img" alt="" />
                                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                                    <div className="stack-bt">
                                        <h5 className="card-title">IN THE HEART OF NATURE</h5>
                                        <p className="card-text">How did we get there?<br />
                                            Find out our story</p>
                                        <Link href="#" className="btn-orange-link">Learn more <img src="images/icons/icon-arrow-right-orange.svg" alt="" /></Link> </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6 col-xl-3">
                            <div className="card card-stack"> <img src="images/stack/stack-4.png" className="bd-placeholder-img bd-placeholder-img-lg card-img" alt="" />
                                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                                    <div className="stack-bt"> <img src="images/icons/icon-mask.svg" className="pb-2" alt="" />
                                        <h5 className="card-title">BENEFITS OF SWIMMING</h5>
                                        <p className="card-text">Your stay, our passions.<br />
                                            Read our mission for a
                                            new hospitality.</p>
                                        <Link href="#" className="btn-orange-link">Learn more <img src="images/icons/icon-arrow-right-orange.svg" alt="" /></Link> </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <div className="card card-stack"> <img src="images/stack/stack-5.png" className="bd-placeholder-img bd-placeholder-img-lg card-img" alt="" />
                                <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                                    <div className="stack-bt">
                                        <h5 className="card-title">SIMPLY LUXURY</h5>
                                        <p className="card-text">Shhhhhhht...!<br />
                                            Discover how we make your privacy our main preoccupation </p>
                                        <Link href="#" className="btn-orange-link">Learn more <img src="images/icons/icon-arrow-right-orange.svg" alt="" /></Link> </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mobile-crd-text arrow-tp"> <Link href="#" className="btn btn-dark orange-btn">Discover more articles</Link> </div>
                </div>
            </div> */}
            <div className="container-sm beyond-container mob-text-cent">
                <div className="text-center">
                    <h1 className="orange-big-title">BEY<img src={icon_star_outine} alt="OrangeStarOutLine" className="start-o  pad-bt-10" />ND <br />
                        YOUR DREAMS,</h1>
                    <h2 className="black-big-title sm-orange-line pb-5">don’t miss it.</h2>
                </div>
                <p className="title-p text-start mb-5">MyHotelMatch is your key to unlocking unparalleled travel experiences. Download the app now and live your journey, not someone else's. With your personalized of hotels by AI and caretake, hotel service crafted for you, be ensure that your trip will be a memory forever. <br />
                    it's time to travel for yourself, with us. Download today .</p>
                {/* <div className="click-here-1 text-center"> <Link href="#" className="btn btn-dark orange-btn">Download the app</Link> <span className="click-pos"><img src="images/icons/phone-arrow-icon.svg" alt="" /> let’s go!</span> </div> */}
            </div>
        </>
    )
}
