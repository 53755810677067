import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import right_arrow from "../assets/icons/right-arrow.svg";

import Apple from '../assets/Apple.svg';
import Google from '../assets/Google.svg';

import article_banner from "../assets/article-banner.png";
import user_small_img from "../assets/WomanLaponia.png";
import user_small_img_2 from "../assets/IglooLappland.png";
import icon_clock from "../assets/icons/icon-clock.svg";
import img_9 from "../assets/LapplandSnowyVillageLampPosts.png";
import img_10 from "../assets/LaponiaNorthernLights.png";

import arti_8 from "../assets/thumbnail/arti_8.png";
import arti_9 from "../assets/thumbnail/arti_9.png";
import arti_10 from "../assets/thumbnail/TropicalInfinityPoolWithOceanView.png";
import arti_11 from "../assets/thumbnail/arti_11.png";

import icon_arrow_right_orange from "../assets/icons/icon-arrow-right-orange.svg";
import icon_charge from "../assets/icons/icon-charge.svg";
import icon_star_outine from "../assets/icons/icon-star-outine.svg";
import icon_mask from "../assets/icons/icon-mask.svg";

import "../css/photo-glider.css";
import "glider-js/glider.min.css";
import PhotosCarousel from "../components/PhotosCarousel/PhotosCarousel.js";

const photosToShow_Arti = [
    { url: arti_8, header_text: "IN THE HEART OF NATURE", icon_url: "", alt_text: "" },
    { url: arti_9, header_text: "BENEFITS OF SWIMMING", icon_url: icon_mask, alt_text: "" },
    { url: arti_10, header_text: "SEA, SUN AND SAND", icon_url: "", alt_text: "WomanSwimmingInInfinityPoolOverlookingOcean" },
    { url: arti_11, header_text: "THE UNKNOWN PARIS", icon_url: "", alt_text: "" },
    { url: arti_8, header_text: "IN THE HEART OF NATURE", icon_url: "", alt_text: "" },
    { url: arti_8, header_text: "IN THE HEART OF NATURE", icon_url: "", alt_text: "" }
];

const thumbnailsCarouselConfig_Arti = {
    itemWidth: 300,
    slidesToShow: "auto",
    slidesToScroll: "auto"
};

export const Article = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    const [currentPhotoIdx, setCurrentPhotoIdx] = React.useState(0);
    const onClickPhotoHandler = React.useCallback(
        (idx) => () => setCurrentPhotoIdx(idx),
        []
    );
    const onSlideChangeHandler = React.useCallback(
        (photoIdx) => {
            setCurrentPhotoIdx(photoIdx);
        },
        [setCurrentPhotoIdx]
    );
    const photos = React.useMemo(
        () =>
            photosToShow_Arti.map((objPhotoUrl, idx) => {
                const key = `${objPhotoUrl.url}_${idx}`;
                let className = "slide__content thumb glider-text-align-left";
                if (currentPhotoIdx === idx) {
                    className += " --current-selected";
                }
                return (
                    <div
                        key={key}
                        className={className}
                        onClick={onClickPhotoHandler(idx)}
                    >
                        <figure className="figure stack-thumb"> <img src={objPhotoUrl.url} className="figure-img img-fluid" alt="..." />
                            <figcaption className="figure-caption">
                                {objPhotoUrl.icon_url.length > 0 ? <img src={icon_mask} alt="" /> : null}
                                <h5 className="card-title">{objPhotoUrl.header_text}</h5>
                                <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </figcaption>
                            <span className="stack-badge">Ambassador</span> </figure>
                    </div>
                );
            }),
        [onClickPhotoHandler, currentPhotoIdx]
    );

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>MyHotelMatch</title>
                <link rel="canonical" href="https://myhotelmatch.com/article" />
                <meta name="description" content="MyHotelMatch" />
                <meta name="keywords" content="MyHotelMatch, Journey" />
            </Helmet>

            <div className="main-content">
                <div className="article-banner">
                    <div className="card card-stack"> <img src={article_banner} className="bd-placeholder-img bd-placeholder-img-lg card-img" alt="" />
                        <div className="card-img-overlay"> <span className="stack-badge">Ambassador</span>
                            <div className="stack-bt">
                                <h5 className="card-title">LAPONIA FOREVER</h5>
                                <p className="card-text">Andrea’s magical journey to Laponia</p>
                            </div>
                        </div>
                    </div>
                    <div className="msg-overlay">
                        <div className="d-flex align-items-end mob-blck ipad-blck mb-3">
                            <div className="flex-grow-0 chat-bub-left right-tri">
                                <h4>Andrea</h4>
                                <p>Sure i’m eager to come!</p>
                            </div>
                            <div className="flex-shrink-0 ms-3"> <img src={user_small_img} className="img-circl" alt="WomanLaponia" /> </div>
                        </div>
                        <div className="d-flex align-items-end mob-blck ipad-blck mb-3">
                            <div className="flex-shrink-0"> <img src={user_small_img_2} className="img-circl" alt="IglooLappland" /> </div>
                            <div className="flex-grow-0 chat-bub-left ms-3 left-tri">
                                <h4>Glass Resort - Johanes</h4>
                                <p>We confirm you a room for 2 weeks, with possibility to extend.</p>
                            </div>
                        </div>
                        <div className="d-flex align-items-end mob-blck ipad-blck">
                            <div className="flex-grow-0 chat-bub-left right-tri">
                                <h4>Andrea</h4>
                                <p>Thx you so much!</p>
                            </div>
                            <div className="flex-shrink-0 ms-3"> <img src={user_small_img} className="img-circl" alt="" /> </div>
                        </div>
                    </div>

                </div>
                <div className="container">
                    <div className="pt-3">
                        <div className="profile-cont float-start"> <img src={user_small_img} alt="" />
                            <p>Andrea Gonzales-Ribeira</p>
                        </div>
                        <div className="date-cont"><img src={icon_clock} alt="" /><span className="ms-3">21/09/23</span></div>
                    </div>

                    <div className="col-md-12">
                        <div className="text-center article-sub-cont">
                            <p className="article-sub-p"> <strong>Featured hotel:</strong> Glass Resort, Rovaniemi, Finland</p>
                            <div className="pt-1">
                                <span className="orange-badge-outline">Wild lovers</span>
                                <span className="orange-badge-outline">Ice lovers</span>
                                <span className="orange-badge-outline">Leisure lovers</span>
                                <span className="orange-badge-outline">Relax lovers</span>
                            </div>
                        </div>
                        <div className="d-flex align-items-center mb-5 mob-blck ipad-blck">
                            <div className="flex-shrink-0 mb-5 mob-centr"> <img src={img_9} className="img-fluid" alt="WinterWonderlandSnowyVillageWithLampPosts" /> </div>
                            <div className="flex-grow-1 ms-5  mob-mar0">
                                <p className="home-p">Andrea, a fighter, had survived a devastating stroke. A moment that had turned her life upside down. But she had a dream: to reinvent herself. So she turned to the icy north of Lapland for inspiration. In the cold Arctic, she discovered a new freelance career. And a mission for a non-profit organization!</p>
                            </div>
                        </div>
                        <div className="text-center mb-5">
                            <h3 className="article-sub-title">The ice slows everything and gives you time for introspection.</h3>
                        </div>
                        <div className="d-flex align-items-center mb-5 mob-blck ipad-blck">
                            <div className="flex-grow-1 mb-5">
                                <p className="home-p mb-4">Her favorite part? Three magical days in the Far North, gliding along on a dogsled, camping in the wilderness, and under the Northern Lights. <br />
                                    Her home? The Glass Resort, a transparent room with a unique view of the snow-covered forest. A place where she could work with an ultra-fast Internet connection.</p>
                                <p className="home-p mb-4"> The open-air Jacuzzi swept her into a bath of warmth and friendship, bringing her friends for life. From illness to magic, Andrea found her new life in the icy landscape of Lapland. What's your own adventure waiting for you? </p>
                                <p className="home-p"> Real peoples, real stories with MHM.</p>
                            </div>
                            <div className="flex-shrink-0 ms-5 mob-mar0 mob-centr"> <img src={img_10} className="img-fluid" alt="NorthernLightsOverSnowyTreesInWinterNight" /> </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid article-caro-cont">
                    <div className="text-center mb-5">
                        <h3 className="article-sub-title-1">You might like those one</h3>
                    </div>
                    <div className="carousel-container mb-5">
                        {/* <figure className="figure stack-thumb"> <img src={arti_8} className="figure-img img-fluid" alt="..." />
                        <figcaption className="figure-caption">
                            <h5 className="card-title">IN THE HEART OF NATURE</h5>
                            <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </figcaption>
                        <span className="stack-badge">Ambassador</span> </figure>
                    <figure className="figure stack-thumb"> <img src={arti_9} className="figure-img img-fluid" alt="..." />
                        <figcaption className="figure-caption">
                            <h5 className="card-title">BENEFITS OF SWIMMING</h5>
                            <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </figcaption>
                        <span className="stack-badge">Ambassador</span> </figure>
                    <figure className="figure stack-thumb"> <img src={arti_10} className="figure-img img-fluid" alt="..." />
                        <figcaption className="figure-caption">
                            <h5 className="card-title">SEA, SUN AND SAND</h5>
                            <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </figcaption>
                        <span className="stack-badge">Ambassador</span> </figure>
                    <figure className="figure stack-thumb"> <img src={arti_11} className="figure-img img-fluid" alt="..." />
                        <figcaption className="figure-caption">
                            <h5 className="card-title">THE UNKNOWN PARIS</h5>
                            <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </figcaption>
                        <span className="stack-badge">Industry trend</span> </figure>
                    <figure className="figure stack-thumb"> <img src={arti_8} className="figure-img img-fluid" alt="..." />
                        <figcaption className="figure-caption">
                            <h5 className="card-title">IN THE HEART OF NATURE</h5>
                            <a href="#" className="btn-orange-link">Learn more <img src={icon_arrow_right_orange} alt="" /></a> </figcaption>
                        <span className="stack-badge">Ambassador</span> </figure> */}
                        {/* <button className="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev"> <span className="carousel-control-prev-icon" aria-hidden="true"></span> </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next"> <span className="carousel-control-next-icon" aria-hidden="true"></span> </button> */}
                        <div className="App">
                            <div className="photos-gallery">

                                <PhotosCarousel
                                    className="thumbnails-wrapper"
                                    config={thumbnailsCarouselConfig_Arti}
                                    currentSlideIdx={currentPhotoIdx}
                                >
                                    {photos}
                                </PhotosCarousel>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="article-about-cont">
                        <div className="text-center">
                            <h3 className="title-sub tex-big orange-txt text-center pb-1">WHAT ABOUT Y<img src={icon_star_outine} alt="" className="pad-bt-10" />u?</h3>
                            <p>Live your own unique story!</p>
                            <div className="text-center mobile-crd-text">
                                <Link to={"https://play.google.com/store/apps/details?id=com.mhmlabs.myhotelmatch"} target="_blank"><img src={Google} alt="Google" style={{ margin: "0 10px" }} /></Link>
                                <Link to={"https://apps.apple.com/fr/app/myhotelmatch/id6451199349?l=en-GB"} target="_blank"><img src={Apple} alt="Apple" style={{ margin: "0 10px" }} /></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
};
