import '../../App.css'

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import mhm_logo from '../../assets/logo-mhm.svg'
import story_lnk_img from '../../assets/icons/story.svg'
import discover_lnk_img from '../../assets/icons/discover.svg'
import commitment_lnk_img from '../../assets/icons/commitment.svg'
import phone_lnk_img from '../../assets/icons/phone.svg'
// import story_lnk from '../../assets/icons/story.svg'
import { useLocation } from 'react-router-dom';

export const AppBar = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const [open, setOpen] = useState(false)

    const location = useLocation();
    const [activeItem, setActiveItem] = useState('');

    const handleItemClick = (itemName) => {
        setActiveItem(itemName);
    };

    useEffect(() => {
        // Get the current pathname from the URL
        const currentPath = location.pathname;
        if (currentPath.includes('ourstory')) {
            setActiveItem('ourstory');
        }
        else if (currentPath.includes('discover')) {
            setActiveItem('discover');
        }
        else if (currentPath.includes('commitment')) {
            setActiveItem('commitment');
        }
        else if (currentPath.includes('contact')) {
            setActiveItem('contact');
        }
        else {
            setActiveItem('');
        }

    }, [location.pathname]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth)
            if (windowSize > 1024) {
                setOpen(false)
            }
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    return (
        <nav className="navbar navbar-expand-lg header" aria-label="Offcanvas navbar large">
            <div className="container-fluid"> <Link className="navbar-brand" to={"/"}><img src={mhm_logo} alt="" /></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar2" aria-controls="offcanvasNavbar2" aria-label="Toggle navigation"> <span className="navbar-toggler-icon"></span> </button>
                <div className="offcanvas offcanvas-start text-bg-dark" tabIndex="-1" id="offcanvasNavbar2" aria-labelledby="offcanvasNavbar2Label">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasNavbar2Label"></h5>
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body">
                        <ul className="navbar-nav justify-content-end flex-grow-1 header-nav">
                            <li className="nav-item active" data-bs-dismiss="offcanvas"> <Link className={activeItem === 'ourstory' ? 'nav-link active' : 'nav-link'}
                                aria-current="page" to={'/ourstory'}><img src={story_lnk_img} alt="" />Our story</Link> </li>
                            {/* <li className="nav-item"> <Link className={activeItem === 'discover' ? 'nav-link active' : 'nav-link'}
                                to={'/discover'}><img src={discover_lnk_img} alt="" />Discover</Link> </li> */}
                            <li className="nav-item" data-bs-dismiss="offcanvas"> <Link className={activeItem === 'commitment' ? 'nav-link active' : 'nav-link'}
                                to={'/commitment'}><img src={commitment_lnk_img} alt="" />Commitments</Link> </li>
                            <li className="nav-item" data-bs-dismiss="offcanvas"> <Link className={activeItem === 'insvestor' ? 'nav-link' : 'nav-link'}
                                to={'https://mymatchingcompany.com'} target='_blank'><img src={discover_lnk_img} alt="" />Investors</Link> </li>
                            <li className="nav-item" data-bs-dismiss="offcanvas"> <Link className={activeItem === 'contact' ? 'nav-link active' : 'nav-link'}
                                to={'/contact'}><img src={phone_lnk_img} alt="" />Contact</Link> </li>
                            {/* <li className="nav-item dropdown"> <a className="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false"> EN </a>
                                <ul className="dropdown-menu dropdown-menu-end">
                                    <li><a className="dropdown-item" href="#">Action</a></li>
                                </ul>
                            </li> */}

                        </ul>
                    </div>
                </div>
            </div>
        </nav>


    )
}
