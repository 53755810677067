import React from 'react';
import { Helmet } from "react-helmet";

export const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Hotel Match - page not found</title>
        <link rel="canonical" href="https://myhotelmatch.com" />
      </Helmet>

      <div className='page-not-found'>
        <h1>404 - Not Found</h1>
        <p>Sorry, the page you are looking for does not exist.</p>
      </div>
    </>
  );
};

