import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/main.css";
import "./css/media.css";
import "./css/bootstrap-icons.css";

import "./App.css";

import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { AppBar } from "./components/AppBar/AppBar";
import { Footer } from "./components/Footer/footer";
import { Home } from "./pages/Home";
import { Become_Partner } from "./pages/Become-Partner";
import { Discover } from "./pages/discover";
import { Article } from "./pages/article";
import { Contact } from "./pages/contact";
import { Commitment } from "./pages/commitment";
import { OurStory } from "./pages/our-story";
import { OnBoarding } from "./pages/onboarding";
import { PageNotFound } from "./pages/pagenotfound";

function App() {
  const [windowSize, setWindowSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth);
    };
    console.log(process.env.REACT_APP_API_URL);

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  });



  return (
    <Router>
      <AppBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/partner" element={<Become_Partner />} />
        <Route path="/ourstory" element={<OurStory />} />
        <Route path="/discover" element={<Discover />} />
        <Route path="/discover/article" element={<Article />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/commitment" element={<Commitment />} />
        <Route path="/onboarding" element={<OnBoarding />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;



