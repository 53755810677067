import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import right_arrow from "../assets/icons/right-arrow.svg";
import contact_img from "../assets/HotelRoomViewWithWomanAndLuggage.png";

import icon_hotel from "../assets/icons/hotel-1.svg";
import icon_guest from "../assets/icons/guest.svg";
import icon_partners from "../assets/icons/partners.svg";
import icon_suitcase from "../assets/icons/suitcase.svg";
import icon_others from "../assets/icons/other.svg";
import icon_send from "../assets/icons/icon-send-1.svg";
import icon_send_button from "../assets/icons/icon-send-button.svg";

import { addRegistration } from '../api/contact_registration'

import { LazyImage } from '../components/LazyImage/LazyImage'


export const Contact = () => {
  const [windowSize, setWindowSize] = useState(window.innerWidth)
  const [amdeusCodeIsVisibile, setAmdeusCodeIsVisibile] = useState(false)
  const [subjectOptions, setSubjectOptions] = useState(['Need for information', 'You have a problem with a reservation', 'You have a problem related to the application', 'Other'])

  const [submitButtonStyle, setSubmitButtonStyle] = useState('btn btn-dark contact-btn orange-btn');

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowResize)

    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  })

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const hotelFields = [{ name: "email", placeHolder: "Email*" },
  { name: "phone", placeHolder: "Phone Number*" },
  { name: "position", placeHolder: "Position*" },
  { name: "hotelName", placeHolder: "Hotel Name*" },
  { name: "city", placeHolder: "City*" },
  { name: "website", placeHolder: "Website (Optional)" }];

  const guestFields = [{ name: "email", placeHolder: "Email*" },
  { name: "phone", placeHolder: "Phone Number*" },
  { name: "city", placeHolder: "City*" },
  { name: "hearAboutUs", placeHolder: "How did you hear about us?*" }];

  const partnerFields = [{ name: "email", placeHolder: "Email*" },
  { name: "position", placeHolder: "Position*" },
  { name: "phone", placeHolder: "Phone Number*" },
  { name: "companyName", placeHolder: "Company Name*" },
  { name: "city", placeHolder: "City*" },
  { name: "website", placeHolder: "Website (Optional)" },
  { name: "hearAboutUs", placeHolder: "How did you hear about us?*" }];

  const investorFields = [{ name: "position", placeHolder: "Position*" },
  { name: "email", placeHolder: "Email*" },
  { name: "phone", placeHolder: "Phone Number*" },
  { name: "city", placeHolder: "City*" },
  { name: "companyName", placeHolder: "Company Name*" },
  { name: "website", placeHolder: "Website (Optional)" },
  { name: "hearAboutUs", placeHolder: "How did you hear about us?*" }];

  const othersFields = [{ name: "position", placeHolder: "Position*" },
  { name: "email", placeHolder: "Email*" },
  { name: "phone", placeHolder: "Phone Number*" },
  { name: "city", placeHolder: "City*" },
  { name: "companyName", placeHolder: "Company Name*" },
  { name: "website", placeHolder: "Website (Optional)" },
  { name: "hearAboutUs", placeHolder: "How did you hear about us?*" }];

  const [activeButton, setActiveButton] = useState('others');
  const [displayedForms, setDisplayedForms] = useState(othersFields);

  const handleCardClick = (cardName) => {
    setErrors({});
    setActiveButton(cardName); // Set the active button ID
    setAmdeusCodeIsVisibile(false)

    switch (cardName) {
      case "guest":
        // const namesObject = guestFields.reduce((acc, item) => {
        //   acc[item.name] = ''; // Set each key to an empty string
        //   return acc;
        // }, {});
        setSubjectOptions(['Need for information', 'You have a problem with a reservation', 'You have a problem related to the application', 'Other']);
        setRegistrationData(generateEmptyObject(guestFields, cardName));
        setDisplayedForms(guestFields);
        break;
      case "partner":
        setSubjectOptions(['You want to be part of the new world', 'Other']);
        setRegistrationData(generateEmptyObject(partnerFields, cardName));
        setDisplayedForms(partnerFields);
        break;
      case "investor":
        setSubjectOptions(['You want to be part of the new world', 'You require financial information', 'You need a call-back', 'Other']);
        setRegistrationData(generateEmptyObject(investorFields, cardName));
        setDisplayedForms(investorFields);
        break;
      case "others":
        setSubjectOptions(['Need for information', 'You have a problem with a reservation', 'You have a problem related to the application', 'Other']);
        setRegistrationData(generateEmptyObject(othersFields, cardName));
        setDisplayedForms(othersFields);
        break;
      default:
        setSubjectOptions(['You want to be part of the new world', 'You have a Back Office problem', 'You have a problem with a reservation', 'Other']);
        setAmdeusCodeIsVisibile(true)
        setRegistrationData(generateEmptyObject(hotelFields, cardName));
        setDisplayedForms(hotelFields);
        break;
    }

  };

  function generateEmptyObject(objFields, cardName) {
    //create empty object for each card
    const emptyObject = objFields.reduce((acc, item) => {
      acc[item.name] = '';
      return acc;
    }, {});

    // common fields
    emptyObject['firstname'] = ''; // Add the extra key and value
    emptyObject['lastname'] = '';
    if (cardName === 'hotel') {
      emptyObject['amadeus'] = '';
    }

    emptyObject['subject'] = '';
    emptyObject['message'] = '';


    return emptyObject;
  }

  const [RegistrationData, setRegistrationData] = useState(generateEmptyObject(othersFields, 'others'));

  const [errors, setErrors] = useState({}); // State to store validation errors

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRegistrationData({ ...RegistrationData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  }
  const handleLiClick = (subjectName) => {
    //for subject selection
    setRegistrationData({ ...RegistrationData, subject: subjectName });
    setErrors({ ...errors, subject: '' });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm(RegistrationData);

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return -1;
    }

    // console.log(RegistrationData)


    addRegistration(RegistrationData, (response) => {
      if (response?.status === 'success') {
        setSubmitButtonStyle('btn btn-dark contact-btn contact-animate orange-btn');

        setTimeout(() => {
          setSubmitButtonStyle('btn btn-dark contact-btn orange-btn');
          alert('Registration submitted successfully')
        }, 1000);
        //
        if (activeButton === 'hotel') {
          setRegistrationData(generateEmptyObject(hotelFields, activeButton));
        }
        else if (activeButton === 'guest') {
          setRegistrationData(generateEmptyObject(guestFields, activeButton));
        }
        else if (activeButton === 'partner') {
          setRegistrationData(generateEmptyObject(partnerFields, activeButton));
        }
        else if (activeButton === 'investor') {
          setRegistrationData(generateEmptyObject(investorFields, activeButton));
        }
        else if (activeButton === 'others') {
          setRegistrationData(generateEmptyObject(othersFields, activeButton));
        }

      } else {
        console.log(response?.message);
        alert(`Error occured.`)
      }
    })

  }

  const validateForm = (data) => {
    const errors = {};

    if ('firstname' in data && data.firstname.trim() === '') {
      errors.firstname = 'First Name is required';
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if ('email' in data && !emailRegex.test(data.email.trim())) {
      errors.email = 'Invalid email. Please enter a valid email address.';
    }

    if ('lastname' in data && data.lastname.trim() === '') {
      errors.lastname = 'Last Name is required';
    }

    if ('phone' in data && data.phone.trim() === '') {
      errors.phone = 'Phone number is required';
    }

    if ('companyName' in data && data.companyName.trim() === '') {
      errors.companyName = 'Company name is required';
    }

    if ('position' in data && data.position.trim() === '') {
      errors.position = 'Position is required';
    }

    if ('city' in data && data.city.trim() === '') {
      errors.city = 'City is required';
    }

    if ('hearAboutUs' in data && data.hearAboutUs.trim() === '') {
      errors.hearAboutUs = 'How did you hear about us? is required';
    }

    if ('subject' in data && data.subject.trim() === '') {
      errors.subject = 'Select subject';
    }

    if ('message' in data && data.message.trim() === '') {
      errors.message = 'Message is required';
    }

    if ('amadeus' in data && data.amadeus.trim() === '') {
      errors.amadeus = 'Amadeus property code is required';
    }

    return errors;
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Connecting with MyHotelMatch: How to Reach Us</title>
        <link rel="canonical" href="https://myhotelmatch.com/contact" />
        <meta name="description" content="Connect with us at MyHotelMatch - your gateway to a world of comfort and luxury. Contact us now for an unforgettable travel experience!" />
        <meta name="keywords" content="Hotel, luxury, tailor-made, stay, Boutique accommodations, Lavish lodging, Exclusive retreat, Personalized escape, Qualitative hotel, Customized stay, Tailored luxury, Bespoke hospitality, Handcrafted experience, High-end residence, Deluxe suite, Unique lodging, Tailor-made amenities, Luxury accommodations, Tailored hospitality, Luxury retreat, Tailored comfort, Premium stay, Customized lodging, VIP hotel, Luxury getaway, Tailor-made service, Upscale accommodations, Tailored vacation, Luxury oasis" />
      </Helmet>

      <div className="main-content">
        <div className="contact-banner-cont">
          <div className="d-flex mb-5 mob-blck ipad-blck">
            <div className="align-self-start col-md-12 col-lg-6">
              <div className="contact-ban-bor">
                <div className="contact-banner">
                  <div className="title-cont">
                    <h3 className="title-sub tex-big orange-txt"><span>How</span>
                      <div className="sub">can we <span className="art-text">help</span> you?</div>
                    </h3>
                    <p className="arrow-cont">Tell us what you need so we<br />
                      can help you!</p>

                    <div className="contact-tab-cont">
                      <button className={`${activeButton === 'hotel' ? 'active' : ''}`} onClick={() => handleCardClick('hotel')}><LazyImage image={{ src: icon_hotel, className: "", alt: "icon_hotel" }} /><span>Hotel</span></button>
                      <button className={`${activeButton === 'guest' ? 'active' : ''}`} onClick={() => handleCardClick('guest')}><LazyImage image={{ src: icon_guest, className: "", alt: "icon_guest" }} /><span>Guest</span></button>
                      <button className={`${activeButton === 'partner' ? 'active' : ''}`} onClick={() => handleCardClick('partner')}><LazyImage image={{ src: icon_partners, className: "", alt: "icon_partners" }} /><span>Partner</span></button>
                      <button className={`${activeButton === 'investor' ? 'active' : ''}`} onClick={() => handleCardClick('investor')}><LazyImage image={{ src: icon_suitcase, className: "", alt: "icon_suitcase" }} /><span>Investor</span></button>
                      <button className={`${activeButton === 'others' ? 'active' : ''}`} onClick={() => handleCardClick('others')}><LazyImage image={{ src: icon_others, className: "", alt: "icon_others" }} /><span>Other</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-grow-1 ms-4">
              <div className="form">
                <div className="contact-title">
                  <h1>It’s nice to see you! <LazyImage image={{ src: icon_send, className: "", alt: "icon_send" }} /></h1>
                  <p>Fill out the form to let us know about your request.</p>
                </div>
                <div className="card">
                  <form onSubmit={handleSubmit} id='frmContact'>
                    <div className="card-body" style={{ fontSize: '10px', color: 'red' }}>
                      <div className="row">
                        <div className="col-md-6">
                          <span className="error">{errors.firstname}</span>
                          <input
                            type="text"
                            className="form-line"
                            placeholder="First Name*"
                            name="firstname"
                            onChange={handleChange}
                            value={RegistrationData.firstname}
                          />

                        </div>
                        <div className="col-md-6">
                          <span className="error">{errors.lastname}</span>
                          <input
                            type="text"
                            className="form-line"
                            placeholder="Last Name*"
                            name="lastname"
                            onChange={handleChange}
                            value={RegistrationData.lastname}
                          />

                        </div>
                        {displayedForms.map((rw) => (
                          <div className="col-md-12">
                            <span className="error">{errors[rw.name]}</span>
                            <input
                              type="text"
                              className="form-line"
                              placeholder={rw.placeHolder}
                              name={rw.name}
                              onChange={handleChange}
                              value={RegistrationData[rw.name]}
                            />
                          </div>
                        ))}

                        {/* <div className="col-md-12">
                          <span className="error">{errors.subject}</span>
                          <select
                            className="form-line"
                            name="subject"
                            onChange={handleChange}
                            value={RegistrationData.subject}
                          >
                            <option>Subject *</option>
                            {subjectOptions.map((subjectOptions, index) => (
                              <option key={index} value={subjectOptions}>
                                {subjectOptions}
                              </option>
                            ))}
                          </select>
                        </div> */}
                        {/* accordian start  */}
                        <div class="accordion subject-accrd" id="accordionExample">
                          <span className="error">{errors.subject}</span>
                          <div class="accordion-item">

                            <h2 class="accordion-header">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#subject" aria-expanded="false" aria-controls="collapseThree">
                                {RegistrationData.subject === '' ? 'Subject *' : RegistrationData.subject}
                              </button>
                            </h2>
                            <div id="subject" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                              <div class="accordion-body">
                                <ul class="list-group">
                                  {subjectOptions.map((subjectOptions, index) => (
                                    <li className="list-group-item" data-bs-toggle="collapse" data-bs-target="#subject" aria-expanded="false" aria-controls="subject" name='subject' value={subjectOptions} onClick={() => handleLiClick(subjectOptions)}>
                                      {subjectOptions}
                                    </li>
                                  ))}


                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* accordian end  */}
                        {amdeusCodeIsVisibile &&
                          <div className="col-md-12" >
                            <span className="error">{errors.amadeus}</span>
                            <input
                              type="text"
                              className="form-line"
                              placeholder="Amadeus property code*"
                              name="amadeus"
                              onChange={handleChange}
                              value={RegistrationData.amadeus}
                            />
                          </div>
                        }
                        <div className="col-md-12">
                          <span className="error">{errors.message}</span>
                          <textarea className="form-line" placeholder="Message*" name='message'
                            onChange={handleChange} value={RegistrationData.message} rows="3">
                          </textarea>
                        </div>
                        <div className="text-left">
                          <button type="submit" className={submitButtonStyle}>
                            <span>Reach us <img src={icon_send_button} alt="" /></span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
