import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

export const LazyImage = ({ image }) => {
    return (
        <LazyLoadImage
            alt={image.alt}
            src={image.src}
            className={image.className}
        />
    )
}