import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";

import right_arrow from "../assets/icons/right-arrow.svg";
// import contact_img from "../assets/contact-img.png";


export const Commitment = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth)
        }

        window.addEventListener('resize', handleWindowResize)

        return () => {
            window.removeEventListener('resize', handleWindowResize)
        }
    })

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Securing Your Privacy at MyHotelMatch</title>
                <link rel="canonical" href="https://myhotelmatch.com/commitment" />
                <meta name="description" content="Delve into MyHotelMatch's dedication to protecting your personal information. Trust us to keep your data safe and secure." />
                <meta name="keywords" content="Hotel, luxury, tailor-made, stay, Boutique accommodations, Lavish lodging, Exclusive retreat, Personalized escape, Qualitative hotel, Customized stay, Tailored luxury, Bespoke hospitality, Handcrafted experience, High-end residence, Deluxe suite, Unique lodging, Tailor-made amenities, Luxury accommodations, Tailored hospitality, Luxury retreat, Tailored comfort, Premium stay, Customized lodging, VIP hotel, Luxury getaway, Tailor-made service, Upscale accommodations, Tailored vacation, Luxury oasis" />
            </Helmet>

            <div className="main-content">
                <div className="main-title">
                    <div className="d-flex justify-content-center">
                        <h3 className="title-sub orange-txt shuffle-txt tex-big  mob-text-cent">OUR COMMITMENT
                            <div className="sub line-5">to <span className="art-text confident">confidentiality</span></div>
                        </h3>
                    </div>
                </div>
                <div className="container">
                    <div className="d-flex mb-5 mob-blck ipad-blck">
                        <div className="flex-grow-1">
                            <div className="mb-5 pb-3">
                                <h3 className="title-sub list-titl pb-3"> <span className="numb">1</span> <span>TRUST IS FRAGILE: <br />
                                    Guaranteeing a safe and reliable place <br />
                                    to share your travel habits</span></h3>
                                <p className="home-p mob-text-cent">The mission of the MyHotelMatch security team is to create the best possible security system, so that our members can enjoy a private and secure environment in which to they can fully experience their travel research. We understand that you are entrusting us with your personal data. Implementing a strong, transparent and accountable security programme is at the heart of our commitment to you.
                                </p>
                            </div>
                            <div className="ms-5 dis-none-mob">
                                <h3 className="title-sub list-titl pb-3"> <span className="numb">2</span> <span>THERE’S STRENGTH IN NUMBERS: <br />
                                    We're all committed to reporting faults</span></h3>
                                <p className="home-p mob-text-cent mb-4">MyHotelMatch collects feedback in order to identify potential problems and strengthen the security of its application, infrastructure and member data. We encourage anyone with expertise to report any unidentified vulnerabilities. We are committed to resolving any security issues responsibly and promptly. Thank you for helping us to ensure the security of our members.</p>
                            </div>
                        </div>
                        <div className="flex-shrink-0 col-md-4  mb-5 mob-blck ipad-blck">
                            <div className="orangebt-line">
                                <div className="video-container-sm">
                                    <video autoPlay loop muted playsInline>
                                        <source src="https://storage.googleapis.com/mhm-medias/store-front/video-1.mp4" type="video/mp4" />
                                        Your browser does not support the video tag. </video>
                                </div>
                            </div>

                            <div className="ms-5 dis-blk-mob">
                                <h3 className="title-sub list-titl pb-3"> <span className="numb">2</span> <span>THERE’S STRENGTH IN NUMBERS: <br />
                                    We're all committed to reporting faults</span></h3>
                                <p className="home-p mob-text-cent mb-4">MyHotelMatch collects feedback in order to identify potential problems and strengthen the security of its application, infrastructure and member data. We encourage anyone with expertise to report any unidentified vulnerabilities. We are committed to resolving any security issues responsibly and promptly. Thank you for helping us to ensure the security of our members.</p>
                            </div>


                        </div>
                    </div>
                </div>
                <div className="container-fluid p-0">
                    <div className="d-flex  mb-5 mob-blck ipad-blck">
                        <div className="flex-shrink-0 align-self-end col-md-4">
                            <div className="peacefully">
                                <div className="video-container-sm">
                                    <video autoPlay loop muted playsInline>
                                        <source src="https://storage.googleapis.com/mhm-medias/store-front/video-2.mp4" type="video/mp4" />
                                        Your browser does not support the video tag. </video>
                                </div>
                                <h4>peacefully</h4>
                            </div>
                        </div>
                        <div className="flex-grow-1 p-5 pb-0 mob-pad-ad">

                            <div className="ms-0">
                                <h3 className="title-sub list-titl pb-3 title-sub-commitment"> <span className="numb">3</span> <span>CONTINUOUS PROGRESS: <br />
                                    We're constantly improving</span></h3>
                                <p className="home-p mob-text-cent">Our commitment to continuous improvement demonstrates the value we place on your ongoing satisfaction. By paying close attention to members' comments and suggestions, we show that we're attentive to your needs and want to meet your expectations as fully as possible. This continuous improvement approach is essential to adapting the service to changing needs and maintaining its relevance. Above all, it is a mark of our respect for you.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
